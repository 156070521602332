import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import SecondaryInput from '../../../../components/inputs/SecondaryInput'
import * as yup from 'yup'
import * as validations from 'validations-br'
import Header from '../../components/Header'
import { useContext } from 'react'
import PrimaryButton from '../../../../components/buttons/PrimaryButton'
import { OnboardingContext } from '../../states/OnboardingContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper'
import H3 from '../../../common/components/Typography/H3'
import { cnpjMask } from '../../../../utils/string'
import { OriginContext } from '../../../../states/origin/OriginContext'

interface IAnalyzingCompanyProps {
  className?: string
}

interface IAnalyzeCompanyForm {
  documentNumber: string
}

const schema = yup.object().shape({
  documentNumber: yup
    .string()
    .test(
      'is-cnpj',
      'Confira se o CNPJ está digitado corretamente.',
      (value?: string) => !!(value && validations.validateCNPJ(value))
    )
})

const CompanyDocumentNumberAnalysis: React.FC<IAnalyzingCompanyProps> = ({ className }: IAnalyzingCompanyProps) => {
  const { createCompanyProposal, isCreatingCompanyProposal } = useContext(OnboardingContext)
  const { originInfos } = useContext(OriginContext)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAnalyzeCompanyForm>({
    resolver: yupResolver(schema)
  })

  return (
    <PageWrapper className={className}>
      <Header />
      <div className="company_document_container">
        <H3>Bem-vindo ao nosso simulador!</H3>
        <p className="company_document__welcome_text">
          Para uma <span>análise</span> da sua empresa, comece informando o seu CNPJ.
        </p>
        <form onSubmit={handleSubmit(({ documentNumber }) => createCompanyProposal({ documentNumber, profile: 'C' }))}>
          <SecondaryInput
            className={'company_document___input'}
            label="CNPJ"
            mask="##.###.###/####-##"
            {...register('documentNumber')}
            errorMessage={errors?.documentNumber?.message}
            data-testid="documentNumber-input-test"
          />
          <PrimaryButton type="submit" isLoading={isCreatingCompanyProposal} data-testid="documentNumber-button-test">
            Enviar
          </PrimaryButton>
        </form>
        <div className="company_document_________redirect-text">
          <p>Quer ser um financiador?</p>
          <span onClick={() => navigate('/onboarding/create-user?type=buyer')} data-testid="documentNumber-span-test">
            Clique aqui
          </span>
        </div>
      </div>
    </PageWrapper>
  )
}

export default styled(CompanyDocumentNumberAnalysis)`
  .company_document_container {
    max-width: 400px;
    text-align: center;
    width: 100%;

    h3 {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 8px;

      @media (max-width: 500px) {
        padding: 8px 16px;
      }
    }

    button {
      max-width: 296px;
    }

    .company_document___input {
      max-width: 400px;
      padding-bottom: 64px;
      width: 100%;

      @media (max-width: 500px) {
        margin: auto;
        max-width: 368px;
      }
    }

    .company_document__welcome_text {
      padding: 0px 16px 44px;

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-weight: bold;
      }
    }
    .company_document____divisor-container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 24px 0px;

      .company_document_____divisor {
        border: 1px solid;
        color: ${({ theme }) => theme?.colors?.gray?.lighter};
        width: 80px;
      }

      .company_document______divisor-text {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
        margin: 0px 16px;
      }
    }

    .company_document_______bottom-text {
      padding: 0px 40px;

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-weight: bold;
      }
    }

    .company_document________bottom-text-info {
      color: ${({ theme }) => theme?.colors?.gray?.light};
      font-size: 12px;
      padding-top: 16px;

      span {
        color: ${({ theme }) => theme?.styleGuide?.support?.red};
        font-size: 12px;
      }

      @media (max-width: 500px) {
        padding: 16px 16px;
      }
    }

    .company_document_________redirect-text {
      display: flex;
      justify-content: center;
      padding-top: 90px;

      p {
        color: ${({ theme: { colors } }) => colors?.quarternaryFont};
        font-size: 14px;
        padding-right: 8px;
      }

      span {
        color: ${({ theme: { colors } }) => colors?.primary};
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 40px 0px;
    height: 100%;
  }
`
